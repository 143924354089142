






import DocumentDetailsPartial from '@/components/document/Details.vue'

import { DocumentResource }             from '@/models/documents/DocumentResource'
import { documentsModule, stateModule } from '@/store'
import { Component, Vue }               from 'vue-property-decorator'

@Component({
  components: {
    DocumentDetailsPartial
  }
})
export default class DocumentDetails extends Vue {


  private get detail(): DocumentResource | null {
    return documentsModule.detail
  }

  private async submit(): Promise<void> {
    await documentsModule.get()
    stateModule.setLoading(false)
  }

}
